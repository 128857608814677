<template>
  <AppWidgetBlock
    v-bind="{ config }"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="d-flex justify-center">
      <USAHeatmap
        :config="getHeatMapConfig"
        style="height: 225px;"
        :legend-groups="legendGroups"
        :map-id="config.id"
      />
    </div>
    <div class="d-flex flex-wrap justify-center">
      <div
        v-for="(legendGroup, i) in legendGroups"
        :key="i"
        class="d-flex align-center mx-2"
      >
        <div class="legend-cube mr-1" :style="{backgroundColor: legendGroup.color}" />
        <div class="legend-text">
          <template v-if="legendGroup.min !== legendGroup.max">
            {{ legendGroup.min.toLocaleString() }} - {{ legendGroup.max.toLocaleString() }}
          </template>
          <template v-else>
            {{ legendGroup.min }}
          </template>
        </div>
      </div>
    </div>
  </AppWidgetBlock>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import USAHeatmap from "@/sharedComponents/charts/USAHeatmap";

export default {
  name: "USAHeatMapWidget",
  components: {
    AppWidgetBlock,
    USAHeatmap,
  },
  props: {
    config: {
      default: () => ({
        size: "2x2",
        name: "Contact Universe",
      }),
    },
    data: {
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    getHeatMapConfig() {
      return this.data.map(item => ({
        state: item.state,
        count: parseInt(item.count),
      }));
    },
    color() {
      return '#3b6deb';
    },
    legendGroups() {
      if (!this.getHeatMapConfig.length) {
        return [];
      }

      let max = Math.max(...this.getHeatMapConfig.map(i => i.count));
      let step = Math.floor(max / 4) > 0 ? Math.floor(max / 4) : 1;

      const groups = [];
      const colors = [
        this.color + "40",
        this.color + "80",
        this.color + "bf",
        this.color + "ff",
      ];
      for (let i = 0; i < 4; i++) {
        if (max <= ((i + 1) * step)) {
          groups.push({
            min: i * step,
            max,
            color: colors[i],
          });
          break;
        }

        let currMax = ((i + 1) * step) - 1;

        if (i === 0 || i < 0) {
          currMax = step -1;
        } else if (i === 3) {
          currMax = max;
        }

        groups.push({
          min: i * step,
          max: currMax,
          color: colors[i],
        })
      }

      return groups;
    },
  },
};
</script>


<style lang="scss" scoped>
.heatmap-section {
  &__title, &__state {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }

  &__state {
    font-size: 12px;
    color: #66788e;
  }
}
</style>