<template>
  <AppWidgetBlock
    v-bind="{config}"
    @open-settings="$emit('open-settings', $event)"
    @delete-widget="$emit('delete-widget', $event)"
  >
    <div class="d-flex flex-grow-1">
      <div
        v-if="items.length > 1"
        class="side-bar"
      >
        <v-btn
          v-for="item in items"
          :key="item.label"
          block
          depressed
          text
          class="justify-space-between text-left"
          :class="{'v-btn--has-bg  primary--text': selectedType == item.value}"
          max-width="200"
          height="36"
          @click="selectedType = item.value"
        >
          <span class="label text-none">{{ item.label }}</span>
          <v-icon size="12" class="right">
            $chevron_down_thin
          </v-icon>
        </v-btn>
      </div>

      <v-divider
        v-if="items.length > 1"
        vertical
        class="mx-4 my-n4 divider"
      />

      <div class="chart-container">
        <bar-chart
          class="mt-10"
          style="width: 100%"
          :height="180"
          :chart-data="chartData"
          :options="options"
        />
      </div>
    </div>
  </AppWidgetBlock>
</template>

<script>
import AppWidgetBlock from "@/views/Dashboards/Widgets/components/AppWidgetBlock";
import BarChart from "@/sharedComponents/charts/BarChart";
import { uniq } from "lodash"

export default {
  name: "BarChartWidget",
  components: {
    AppWidgetBlock,
    BarChart
  },
  props: {
    config: {
      default: () => ({
        size: '2x1',
        name: 'Best Day'
      })
    },
    data: {
      type: Object,
      required: true,
    }
  },
  data(){
    return {
      selectedType: this.config.selectedType,
      items: this.config.items,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: this.config.hideLegend
          ? {display: false}
          : {
            position: "bottom",
            labels: {
              usePointStyle: true,
            },
          },
        scales: {
          xAxes: [{
            gridLines: {
              drawOnChartArea: false
            },
            stacked: true,
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 5,
              callback: function(value) {
                return new Intl.NumberFormat().format(value);
              }
            },
            stacked: true,
          }],
        }
      }
    }
  },
  computed: {
    getLabel() {
      const allLabel = this.data[this.selectedType]?.map(item => item[this.selectedType])
      return uniq(allLabel);
    },
    getData() {
      return [{
        backgroundColor: this.getColor(0),
        label: 'Count',
        barThickness: 10,
        datalabels: {
          display: false,
        },
        data: this.data[this.selectedType]?.map(item => item.count) ?? [],
      }]
    },
    chartData() {
      return {
        labels: this.chartLabels,
        datasets: this.datasets,
      }
    },
    chartLabels() {
      const allLabel = this.data[this.selectedType]?.map(item => item.label) ?? [];
      return uniq(allLabel);
    },
    datasets() {
      return this.getData
    },
  },
  watch: {
  },
  methods: {
    getColor(index) {
      const colorArr = [
        "#2B84EB",
        "#FD6868",
        "#EF9F31",
        "#7CD9D6",
        "#66CC66",
        "#6699CC",
        "#CC6699",
        "#FFCC99",
        "#CC66CC",
        "#881122",
        "#CCCCCC",
      ]
      if (index > colorArr.length - 1) {
        return colorArr[colorArr.length - 1]
      }
      return colorArr[index]
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  max-width: 150px;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0 !important;
}

.right {
  transform: rotate(-90deg);
}

.divider {
  max-height: calc(100% + 32px) !important;
  min-height: calc(100% + 32px) !important;
}

.chart-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
}

.chart-container {
  width: 100%;
}
</style>