<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <div>Rep Management Report</div>
      </template>
      <template #subheader>
        <div class="d-flex">
          <div class="flex-fill">
            Generate your rep management report
          </div>
          <CustomDateRangePicker
            v-model="dateRanges"
            :format="showDateFormat"
            :max-date="new Date()"
          />
        </div>
      </template>
    </PageHeader>

    <v-container
      fluid
      class="mt-4"
      :style="{
        height: dynamic_height ? dynamic_height + 'px' : 'auto',
      }"
    >
      <v-row class="grid-stack grid-stack-12" style="z-index: 7">
        <div
          v-for="report_widget in reportWidgets"
          :id="'report-widget-' + report_widget.id"
          :key="'report-widget-' + report_widget.id"
          :class="{
            'grid-stack-item': true,
            'ui-draggable-disabled': true,
            'ui-resizable-disabled': true,
          }"
          :gs-x="report_widget.xPosition"
          :gs-y="report_widget.yPosition"
          :gs-w="report_widget.width"
          :gs-h="report_widget.height"
        >
          <div class="grid-stack-item-content">
            <component
              :is="report_widget.renderComponentName"
              class="item-component"
              :config="report_widget"
              :data="report_widget.data"
              vertical
            />
          </div>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import PageHeader from "@/sharedComponents/PageHeader";
import CountWidget from "@/sharedComponents/widgets/CountWidget";
import LineChartWidget from "@/sharedComponents/widgets/LineChartWidget";
import PieChartWidget from "@/sharedComponents/widgets/PieChartWidget";
import BarChartWidget from "@/sharedComponents/widgets/BarChartWidget";
import USAHeatMapWidget from "@/sharedComponents/widgets/USAHeatMapWidget";
import CustomDateRangePicker from "@/sharedComponents/CustomDateRangePicker";

import { GridStack } from "gridstack";
import "gridstack/dist/gridstack.min.css";

const showDateFormat = 'MM/DD/YYYY'

export default {
  name: "RepManagementReportView",
  metaInfo: {
    title: "Rep Management Report",
  },
  components: {
    PageHeader,
    CountWidget,
    LineChartWidget,
    PieChartWidget,
    BarChartWidget,
    USAHeatMapWidget,
    CustomDateRangePicker,
  },
  mixins: [datesMixin],
  props: {},
  data() {
    return {
      dateRanges: {
        start: new Date().setDate(new Date().getDate() -6),
        end: new Date(),
      },
      showDateFormat,
      grid: null,
      grid_config: {
        alwaysShowResizeHandle: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ),
        resizable: true,
        column: 12,
        cellHeight: "160px", // minimum size when height of block equals 1. Should be high as much as possible, small value (1px) may cause performance issues
        acceptWidgets: false, // Disable dragging outside widgets
        float: true, // Option to correctly flow added widgets
      },
      dynamic_height: 0,
      showSignUpsByTerritory: false,
      reportData: null,
    };
  },
  computed: {
    reportWidgets() {
      const reports = {
        totalReps: {
          id: 1,
          xPosition: 0,
          yPosition: 0,
          widget_id: 1,
          name: "Total Unique Reps Assigned",
          height: 1,
          width: 4,
          componentName: "app.widget.totalrepscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          data: {},
          component: "TotalRepsCountWidget",
        },
        zipCode: {
          id: 2,
          xPosition: 4,
          yPosition: 0,
          widget_id: 2,
          name: "Total Zip Code coverage",
          height: 1,
          width: 4,
          componentName: "app.widget.totalrepscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          update_key: "73ae8b077175cb23ed5a8c30e74b2064",
          data: {},
          component: "TotalRepsCountWidget",
          configuration: {
            color: "#5aa43b",
          },
        },
        whiteSpace: {
          id: 3,
          xPosition: 8,
          yPosition: 0,
          widget_id: 3,
          name: "Total White Space Zip Codes",
          height: 1,
          width: 4,
          componentName: "app.widget.totalrepscountwidget",
          renderComponentName: "CountWidget",
          account_config: null,
          update_key: "73ae8b077175cb23ed5a8c30e74b2064",
          data: {},
          component: "TotalRepsCountWidget",
          configuration: {
            color: "#ff5252",
          },
        },
        signUpsByTerritory: {
          id: 4,
          xPosition: 0,
          yPosition: 1,
          widget_id: 4,
          height: 2,
          width: 12,
          componentName: "app.widget.barchartwidget",
          renderComponentName: "BarChartWidget",
          data: {},
          name: "Reps Assigned per Territory",
          selectedType: "territory",
          hideLegend: true,
          items: [],
        },
        repsByState: {
          id: 10,
          widget_id: 10,
          xPosition: 0,
          yPosition: 3,
          height: 2,
          width: 6,
          name: "Contacts with Reps by State",
          componentName: "app.widget.usaheatmapwidget",
          renderComponentName: "USAHeatMapWidget",
          data: [],
        },
        pieChart: {
          id: 7,
          widget_id: 7,
          xPosition: 6,
          yPosition: 3,
          height: 2,
          width: 6,
          name: "Rep-Assigned vs Whitespace Contacts",
          componentName: "app.widget.totalvisitorscountwidget",
          renderComponentName: "PieChartWidget",
          data: [],
        },
        overTime: {
          id: 6,
          widget_id: 6,
          xPosition: 0,
          yPosition: 5,
          height: 2,
          width: 12,
          name: "Total HCPs Assigned to Reps Over Time",
          componentName: "app.widget.linechartwidget",
          renderComponentName: "LineChartWidget",
          lineChartHeight: 250,
          defaultDataType: 'reps',
          data: {},
        },
      };

      if (!this.reportData) {
        return reports;
      }

      reports.totalReps.data = {count: this.reportData.totalRepsAssigned ?? 0};
      reports.zipCode.data = {count: this.reportData.coveredZipCodes ?? 0};
      reports.whiteSpace.data = {count: this.reportData.whiteSpaceZipCodes ?? 0};

      if (this.reportData.signUpsByTerritory && this.reportData.signUpsByTerritory.length > 1) {
        reports.signUpsByTerritory.data = {
          territory: this.reportData.signUpsByTerritory.map(elem => ({
            ...elem,
            label: elem.territory,
          })),
        };
      } else {
        delete reports.signUpsByTerritory;

        reports.repsByState.yPosition = 1;
        reports.pieChart.yPosition = 1;
        reports.overTime.yPosition = 3;
      }

      reports.repsByState.data = this.reportData.signUpsByState;
      reports.pieChart.data = [
        {
          total: this.reportData.contactWithRepsCount,
          label: 'Rep-Assigned Contacts',
        },
        {
          total: this.reportData.whiteSpaceContactsCount,
          label: 'White Space Contacts',
        },
      ];
      reports.overTime.data = {
        'reps': {
          stat: '',
          label: "Total Reps Assigned",
          value: "reps",
          chartData:  this.reportData?.signUpsByDate,
        }
      };

      return reports;
    },
  },
  watch: {
    dateRanges: {
      deep: true,
      handler() {
        this.getReportData();
      }
    },
  },
  mounted() {
    this.initGrid();
    this.getReportData();
  },
  methods: {
    initGrid() {
      this.grid = GridStack.init(this.grid_config);
      this.grid.enableMove(false, false);
      this.grid.enableResize(false, false);
      window.grid = this.grid;

      setTimeout(this.calculateDynamicHeight.bind(this), 500);
    },
    calculateDynamicHeight() {
      // find lowest widget
      const domWidgets = document.querySelectorAll(".grid-stack-item");
      let bottomHeight = 0;
      for (const domWidget of domWidgets) {
        const bottomOfWidget = domWidget.offsetTop + domWidget.offsetHeight;
        if (bottomOfWidget > bottomHeight) {
          bottomHeight = bottomOfWidget;
        }
      }

      this.dynamic_height = bottomHeight > 0 ? bottomHeight + 50 : 0;
    },
    async getReportData() {
      const reportData = {};
      if (this.dateRanges?.start && this.dateRanges?.end) {
        reportData.startDate = this.getSystemDateFormat(this.dateRanges.start);
        reportData.endDate = this.getSystemDateFormat(this.dateRanges.end);
      }

      const resp = await this.$rest.plugins.repManagement.getReportData(reportData);

      if (resp.data) {
        this.reportData = resp.data;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import url("../../../../node_modules/gridstack/dist/gridstack-extra.css");

.item-component {
  height: 100%;
}
.widget-block__title {
  margin-bottom: 5px !important;
}
</style>
